* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: $white-smoke;
    &:hover {
      background-color: adjust-hue($color: $white-smoke, $degrees: 120);
    }
  }
}

body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
