@import "../../2-components/carousel";

.header{
    width: 100%;
    height: 50vh;
    min-height: 400px;
    position: absolute;
    top: 75px;
    left: 0;
    box-shadow: 0.1px 0.1px 3px;
    @include netbook{
        height: 80vh;
    }
}