/*palette*/
$dark-blue: #24385b;
$blue: #235ab9;
$light-blue: #1089ff;
$gold: #d8ca93;
$red: red;
$dark-gray: gray;
$gray: #96a2b6;
$light-gray: lightgray;
$gray-smoke:#DCE4E7;
$gray-shadow:#a3aba0;
$white-smoke: #efeff1;
$white: #fff;
$black: #000;

/*backgrounds*/
$nav-bg: $dark-blue;
$ft-bg: $white-smoke;
$spinner-bg: $gray-smoke;

/*buttons / links*/
$button: $blue;
$arrow-button: $blue;
$button-active: $light-blue;
$links: $blue;
$links-active: $light-blue;

/*text*/
$text-important: $dark-blue;
$placeholder-text: $light-gray;
$text-normal: $dark-gray;
$underline: $white-smoke;
$underline-semi-bold: $light-gray;
$underline-bold: $dark-gray;

/*others*/
$bullet: $white;
$bullet-active: $light-blue;
$placeholder-input: $dark-gray;
