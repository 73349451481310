@import "../0-abstracts/";

.footer__arrow-box {
    @include display-flex($align: flex-end);
    svg {
      transform: rotate(90deg);
      width: 50px;
      height: 50px;
      path {
        fill: $arrow-button;
        filter:drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.1));
      }
      &:hover{
        cursor: pointer;
        path{
          fill: $button-active;
        } 
      }
    }
}