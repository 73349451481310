$breakpoints: (
  big-phone: 525px,
  tablet: 768px,
  netbook: 1024px,
  notebook: 1240px,
  desktop: 1440px,
);

$nav-padding: (
  null: 24px 32px,
  tablet: 24px 64px,
  netbook: 24px 64px,
  notebook: 24px 96px,
  desktop: 24px 96px,
);

$carousel-padding: (
  null: 0,
  netbook:  0 64px,
  notebook: 0 96px,
  desktop: 0 96px,
);

$padding:(
  null: 32px,
  tablet: 64px,
  netbook: 64px,
  notebook: 64px 96px,
  desktop: 64px 96px,
);

$padding-contact:(
  null: 32px,
  tablet: 64px,
  netbook: 64px,
  notebook: 48px,
  desktop: 64px 96px,
);

$blurred-text-padding:(
  null: 8px,
  big-phone: 16px,
);

$drawer-wrapper-padding:(
  null: 24px 32px,
  big-phone: 24px 32px,
  tablet: 24px 64px
);

$copyright-padding: (
  null: 32px 0,
  tablet: 32px 0,
  netbook: 48px 0 0,
  notebook: 48px 0 0,
  desktop: 64px 0 0,
);
