@import "../0-abstracts/";

.carousel {
  @include display-flex($height: 100%);
  background-color: $black;
  $self: &;
  &__wrapper {
    @include display-flex($height: 100%);
    overflow-x: hidden;
    @include desktop {
      max-width: 1440px;
    }
    #{$self}__slide-wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden !important;
      #{$self}__slide {
        width: 100%;
        height: 100%;
        position: relative;
        &--active-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
          animation: back-to-screen 4s ease infinite forwards;
        }
        #{$self}__blurred-text-box {
          position: absolute;
          @include blurred-text;
          #{$self}__blurred-text {
            @include font($family: "Merriweather", $transform: none);
            &:nth-of-type(2) {
              margin-top: 16px;
            }
          }
        }
        .--text-rent-box {
          width: 100vw;
          padding: 16px;
          top: 75px;
          left: 0;
          transform: translate(0, -250%);
          animation: back-to-screen 4s ease infinite forwards;
          @include tablet {
            @include display-flex($align: flex-start);
            padding: 32px 16px;
          }
        }
        .--text-family-box {
          @include display-flex(
            $width: 40%,
            $height: 100%,
            $justify: center,
            $align: flex-start
          );
          top: 0;
          left: 0;
          max-width: 485px;
          transform: translate(-100%, 0);
          animation: back-to-screen 4s ease infinite forwards;
          @include tablet {
            justify-content: center;
          }
        }
        .--text-fired-box {
          @include display-flex(
            $width: 40%,
            $height: 100%,
            $justify: center,
            $align: flex-start
          );
          top: 0;
          right: 0;
          transform: translate(100%, 0);
          animation: back-to-screen 4s ease infinite forwards;
          @include tablet {
            justify-content: center;
          }
        }
        #{$self}__bullets {
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
          @include display-flex(
            $width: 65px,
            $direction: row,
            $justify: space-around
          );
          #{$self}__bullet {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            box-shadow: 1px 1px 5px #000;
            background-color: $bullet;
          }
        }
      }
    }
  }
}

.--active-bullet {
  background-color: $bullet-active !important;
}
