.input-box {
  position: relative;
  width: 100%;
  height: 64px;
  label {
    @include font(
      $color: $placeholder-text,
      $weight: 300,
      $transform: capitalize,
      $family: "merriweather sans"
    );
    width: auto;
    font-size: 1em;
    text-align: left;
    position: absolute;
    bottom: 16px;
    left: 16px;
    pointer-events: none;
    transition: ease-in 0.3s;
    z-index: 2;
  }
  input {
    @include font(
      $color: $black,
      $weight: 300,
      $transform: none,
      $family: "merriweather sans"
    );
    appearance: none;
    outline: none;
    height: 54px;
    width: 100%;
    font-size: 1em;
    border-radius: 4px;
    border: 0.25px solid $dark-gray;
    padding: 0 0 4px 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    &:focus,
    &:valid {
      border-color: $blue;
      & ~ label {
        transform: translate(0px, -32px);
        color: $blue;
        font-size: 0.8em;
        font-weight: 400;
        background-color: $white;
        padding: 0 4px;
        &::after {
          content: " *";
          color: $red;
        }
      }
    }
    &::-webkit-inner-spin-button,
    &::-webkit-inner-spin-button {
      display: none;
    }
  }
}
