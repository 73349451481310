@import "../0-abstracts/";
@import "./ctaButton";

.modal {
  $self: &;
  z-index: 15;
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba($color: $black, $alpha: 0.8);
    @include display-flex($width: 100vw, $height: 100vh);
    z-index: inherit;
    transition: opacity 0.2s;
  }
  &--success {
    padding: 16px;
    height: 40%;
    min-height: 250px;
    max-height: 300px;
    min-width: 288px;
    max-width: 288px;
    box-shadow: 2px 2px 10px black;
    border-radius: 10px;
    background-color: $white;
    z-index: inherit;
    animation: popUp 0.4s ease-in-out 1 backwards;
    @include tablet {
      width: 60%;
      height: 40%;
      padding: 32px;
      max-width: 400px;
    }
    @include netbook {
      width: 60%;
      height: 40%;
      padding: 32px;
      max-width: 400px;
    }
    .modal__content-wrapper {
      @include display-flex($height: 100%, $justify: space-evenly);
      z-index: inherit;
      .modal__content-box {
        @include display-flex($direction: column-reverse);
        @include tablet {
          flex-direction: row-reverse;
        }
        #{$self}__content-title {
          @include font(
            $family: "merriweather sans",
            $color: $black,
            $transform: none
          );
          text-align: center;
          margin-top: 16px;
          &::first-letter {
            text-transform: capitalize;
          }
          @include tablet {
            margin-top: unset;
          }
        }
        .modal__anim-box {
          width: 30px;
          height: 30px;
          overflow: hidden;
          @include tablet {
            margin-right: 8px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
          }
        }
      }
      .button-box {
        @include display-flex;
      }
    }
  }
}
