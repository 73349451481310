@import "./paddingLists";

.nav
{
    @include padding($nav-padding);
}

header .carousel{
    @include padding($carousel-padding)
}

.row:not(:nth-of-type(1), :nth-of-type(4)),
.row__content,
.expertise,
.footer,
.about-us
{
    @include padding($padding);
}

.contact__wrapper{
    @include padding($padding-contact);
}

.carousel__blurred-text-box{
    @include padding($blurred-text-padding);
}

.nav__drawer-wrapper{
     @include padding($drawer-wrapper-padding)
}

.copyright__box{
    @include padding($copyright-padding)
}

