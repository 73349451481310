@import "../0-abstracts/";

.section__title-box {
  @include display-flex;
  opacity: 0;
  transform: translate(0, -100px);
  @include min-width(350px) {
    align-items: flex-start;
  }
  .section-title {
    @include font($color: $text-important, $family: "merriweather");
    min-width: max-content;
    &::first-letter {
      text-transform: capitalize;
    }
  }
  hr {
    width: 100%;
    border: none;
    border-bottom: 0.25px solid $dark-gray;
  }
}

.--left-translated {
  transform: translate(-100%, 0);
}
.--active-from-top {
  animation: active 0.5s ease-in 1 forwards;
}
