@import "../0-abstracts/";

.expertise__item {
  @include display-flex;
  position: relative;
  $self: &;
  &-img-box {
    @include display-flex($width: 120px, $height: 120px);
    img {
      width: 80px;
      height: 80px;
    }
  }
  &-text-box {
    @include display-flex;
    padding: 0 32px;
    #{$self}-title-box {
      @include display-flex;
      #{$self}-title {
        @include font($color: $text-important, $transform: none, $family: "Merriweather sans", $weight: 400);
        text-align: center;
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }
    #{$self}-description-box {
      @include display-flex;
      margin-top: 8px;
      #{$self}-description {
        text-align: center;
        @include font($color: $text-normal, $transform: none, $family: "Merriweather sans", $weight: 300);
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }
  }
}

