@import "../0-abstracts/";

.MuiButtonBase-root {
  .MuiButton-label {
    a {
      text-decoration: none;
      @include font(
        $family: "Merriweather sans",
        $type: "" !important,
        $weight: 400 !important
      );
    }
  }
  &:hover {
    background-color: $button-active !important;
  }
}

.modal-button {
  width: 150px !important;
  background-color: $button !important;
  border-radius: 20px !important;
  a {
    padding: 4px 0 !important;
    width: 100%;
  }
  &:hover {
    background-color: $button-active !important;
  }
}
