@import "../1-base/";
@import "../0-abstracts/";

.row {
  width: 100%;
  height: 50vh;
  min-height: 400px;
  $self: &;
  &__wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    #{$self}__content {
      @include display-flex($height: 100%);
      #{$self}__text-box {
        @include display-flex;
      }
      .--quote-text-box {
        z-index: 5;
        .--quote-text {
          @include font($transform: none, $family: "Yellowtail");
          filter: drop-shadow(1px 1px 3px rgba(#000, 0.8));
        }
      }
      .--cta-text-box {
        margin: 0px 0 24px 0;
        .--cta-text {
          @include font(
            $family: "Merriweather",
            $transform: capitalize,
            $color: $text-important
          );
          &:nth-child(2) {
            margin-top: 8px;
          }
        }
      }
      .--online-counseling-text-box {
        @include blurred-text($child-tag: "h1");
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        padding: 16px 32px;
        align-items: flex-start;
        @include min-width(1280px) {
          width: 100%;
        }
        .--online-counseling-text {
          @include font($family: "Merriweather sans", $transform: none);
        }
      }
      .button-box {
        @include display-flex;
        position: relative;
        top: 24px;
      }
    }
  }
}

.--cta-content {
  text-align: center;
  @include min-width(1280px) {
    margin-top: unset;
  }
}
.--quote-content {
  position: relative;
  background-image: url("../../Assets/Images/Rows/library.jpg");
  background-size: cover;
  background-position: center center;
  text-align: justify;
  text-indent: 0.25em;
  background-repeat: no-repeat;
  @include tablet {
    background-image: url("../../Assets/Images/Rows/library2.jpg");
    background-attachment: fixed;
  }
  @include min-width(1280px) {
    background-attachment: unset;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.5);
    z-index: 2;
  }
}
.--online-counseling-content {
  @include display-flex($height: 100%, $justify: flex-start);
  background-image: url("../../Assets/Images/Rows/typing.png");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
  text-align: justify;
  text-indent: 0.25em;
  @include min-width(1014px) {
    background-image: url("../../Assets/Images/Rows/typing2.png");
  }
}
