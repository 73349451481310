@import "../1-base/";
@import "../2-components/title";
@import "../2-components/expertiseItem";

.expertise {
  @include display-flex($justify: flex-start);
  min-height: max-content;
  $self: &;
  &__wrapper {
    width: 100%;
    height: 100%;
    @include desktop {
      max-width: 1440px;
    }
    #{$self}__items-wrapper {
      margin: 16px 0;
    }
  }
  .expertise__grid-container{
    .expertise__grid-item{
      transform: rotateY(90deg);
    }
  }
}

.--active-from-top{
  animation: active 0.5s ease-in 1 forwards;
}

.--rotate-tiles {
  .expertise__grid-item {
    @for $i from 1 through 6 {
      &:nth-of-type(#{$i}) {
        $delay: unquote(0.5 + ($i * 0.2) + "s");
        animation: rotate-tiles 0.5s ease $delay 1 forwards;
      }
    }
  }
}

