@import "../../2-components/input";
@import "../../2-components/textarea";

.contact__form {
  @include display-flex;
  &-inputs {
    width: 100%;
    .MuiGrid-container {
      margin: 24px 0 8px 0;
      position: relative;
      right: 8px;
    }
    .contact__grid-container {
      .contact__inner-grid-item {
        opacity: 0;
        transform: translate(0,-100px);
      }
      .contact__grid-item-msg {
        opacity: 0;
        transform: translate(0,100px);
      }
    }
  }
}

.--active-from-top-bottom {
  animation: active 0.5s ease-in 1 forwards;
}
