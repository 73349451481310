@import "../../2-components/menuButton";

.nav {
  @include display-flex;
  $self: &;
  height: 75px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $nav-bg;
  z-index: 10;
  box-shadow: 0.1px 0.1px 5px;
  &__wrapper {
    @include display-flex(
      $height: 100%,
      $direction: row,
      $justify: space-between
    );
    max-width: 1440px;
    #{$self}__brand {
      @include display-flex(
        $width: auto,
        $height: 100%,
        $direction: row,
        $justify: flex-start
      );
      &:hover {
        cursor: pointer;
      }
      #{$self}__logo-box {
        width: 50px;
        height: 50px;
        margin-right: 24px;
        display: none;
        @include tablet {
          display: block;
        }
        img {
          border-radius: 50%;
          height: 50px;
          object-fit: cover;
          object-position: left;
        }
      }
      #{$self}__company-name {
        .company__name {
          @include font($transform: capitalize, $family: "yellowtail");
          min-width: max-content;
        }
      }
    }
  }
}