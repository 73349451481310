@import "../1-base/";
@import "../3-layout/home/nav";
@import "../3-layout/home/header";


.home{
    @include display-flex($height: calc(50vh + 75px));
    position: relative;
    min-height: 400px + 75px;
    @include netbook{
        height: calc(80vh + 75px);
    }
}