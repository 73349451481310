@import "../0-abstracts/";

.loading-spinner {
  @include display-flex($width: 100vw, $height: 100vh);
  background-color: $spinner-bg;
  opacity: 0;
  animation: opacity 2.5s ease 1 forwards;
  $self: &;
  &__wrapper {
    @include display-flex($width: 100%);
    position: relative;
    img {
      height: 250px;
      width: 250px;
      @include desktop {
        height: 400px;
        width: 400px;
      }
    }
    .progress-bar {
      filter: drop-shadow(0.1px 0.1px 3px rgba($black, 0.2));
    }
  }
}
