@import "../0-abstracts/";

.about-us {
  @include display-flex($width: 100vw, $height: calc(100vh - (75px * 2)), $justify: flex-start);
  overflow: hidden auto;
  position: relative;
  top: 75px;
  left: 0;
  $self: &;
  .page-effect {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: bg-moving 1s ease 1 forwards;
    &__trigger {
      position: absolute;
      top: 0;
      left: 0;
      width: 75px;
      height: 75px;
    }
  }
  .about-us__wrapper {
    @include display-flex;
    @include desktop {
      max-width: 1440px;
    }
    #{$self}__img-box {
      filter: drop-shadow(0.1px 0.1px 3px lightgray);
      img {
        width: 200px;
        margin: 24px 0;
        clip-path: circle(40% at 50% 50%);
        opacity: 0;
        animation: active 0.5s ease 1 forwards;
      }
    }
    #{$self}__text-box {
      #{$self}__text {
        @include font(
          $family: "merriweather sans",
          $type: "",
          $weight: 300,
          $transform: none,
          $color: $text-normal
        );
        opacity: 0;
        transform: translate(100%, 0);
        text-align: justify;
        padding: 16px 0;
        line-height: 1.7;
        &::first-letter {
          transform: capitalize;
        }
      }
    }
  }
}

.--anim-back-home {
  animation: bg-moving-back-home 0.5s ease 1 forwards !important;
}
.--opacity {
  animation: opacity-1to0 0.5s ease 1 forwards !important;
}
