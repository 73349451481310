@import "../0-abstracts/";

.nav__drawer {
  position: absolute;
  top: 75px;
  right: 0;
  width: 100%;
  min-height: max-content;
  height: calc(100vh - 75px);
  $self: &;
  .nav__drawer--overlay {
    background-color: rgba($black, 0.6);
    transform: translateX(-100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &-wrapper {
    @include display-flex(
      $width: 70%,
      $height: 100%,
      $justify: flex-start,
      $align: flex-end
    );
    background-color: $white;
    position: absolute;
    top: 0px;
    right: 0;
    max-width: 485px;
    box-shadow: -0.1px 0px 5px black;
    transform: translateX(100%);
    overflow-y: auto;
    #{$self}-items {
      @include display-flex;
      #{$self}-item-box {
        width: 100%;
        padding: 8px 0;
        border-bottom: 0.5px solid $light-gray;
        #{$self}-item {
          @include font(
            $family: "merriweather",
            $transform: capitalize,
            $color: $text-important
          );
          display: block;
          text-decoration: none;
          color: $dark-blue;
          filter: drop-shadow(0.5px 0.5px 5px rgba($black, 0.1));
          &:hover,&:active{
            color: $gold;
          }
        }
      }
    }
  }
}

.--transition-close--left{
  animation: hide-drawer-left 0.2s ease 1 forwards !important;
}
.--transition-close--right{
  animation: hide-drawer-right 0.2s ease 1 forwards !important;
}

.active-drawer {
  animation: show-drawer 0.5s ease 1 forwards;
}
.active-drawer-content {
  animation: show-drawer-content 0.5s ease 1 forwards;
}

