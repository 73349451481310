@import "./typographyLists";

.company__name{
  @include font-size($company-name);
}

.carousel__blurred-text{
  @include font-size($blurred-text);
}

.row__text{
  @include font-size($row-text)
}

.--online-counseling-text{
  @include font-size($section-title)
}

.MuiButton-label{
  @include font-size($cta-button)
}

.section-title{
  @include font-size($section-title);
}

.expertise__item-title{
  @include font-size($item-title);
}

.expertise__item-description{
  @include font-size($item-text);
}

.footer-socials__title{
  @include font-size($socials-title);
}

.footer-links__title{
  @include font-size($links-title);
}

.footer-links__link{
  @include font-size($link);
}

.nav__bar-item{
  @include font-size($cta-button);
}

.nav__drawer-item{
  @include font-size($drawer-item);
}

.about-us__text-title{
  @include font-size($company-name);
}

.about-us__text{
  @include font-size($item-text);
}

.modal__content-title{
  @include font-size($item-text)
}

.copyright__text{
  @include font-size($copyright)
}