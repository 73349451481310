.footer-socials__icon-box {
  @include display-flex;
  @include min-width(960px) {
    @include display-flex($width: 50px);
    align-items: flex-start;
  }
  a {
    width: 36px;
    @include min-width(960px) {
      @include display-flex($align: flex-start, $width: 36px);
    }
    .footer-socials__icon {
      @include display-flex($width: 36px, $height: 36px);
      filter: drop-shadow(1px 1px 3px rgba($black, 0.2));
      &:hover {
        cursor: pointer;
        transform: scale(1.1);
        transition-property: transform;
        transition-duration: 0.2s;
      }
    }
  }
}
